<template>
  <div class="sales-category-setting">
    <PageTitle
      title="堂票設定"
      btn="新增"
      @btnClick="$router.push({
        name: 'CreateClassTicket'
      })"
    />

    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable placeholder="輸入堂票名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="classTicketList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="圖片" align="center">
          <template slot-scope="scope">
            <img style="margin: auto;" width="90" :src="scope.row.Image? imgSource(scope.row.Image) : ThumbnailDefault">
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="availableTimes" label="可使用張數" align="center" />
        <BaseElTableColumn prop="isExp" label="有效期限" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isExp)">{{ (scope.row.isExp)? '有' : '無' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="expDay" label="有效天數" align="center">
          <template slot-scope="scope">
            {{ scope.row.isExp ? scope.row.expDay : '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="specifyExpiredDate" width="140" label="特定截止日期" align="center">
          <template slot-scope="scope">
            <p class="break-word">{{ dateFormat(scope.row.specifyExpiredDate) }}</p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="useTicketGivePermission" prop="isValuable" width="140" label="客人是否可轉贈" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(!scope.row.isValuable)">{{ !(scope.row.isValuable)? '是' : '否' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="useTicketUsagePermission" prop="isClientUsageAllowed" label="客人可否核銷" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isClientUsageAllowed)">{{ (scope.row.isClientUsageAllowed)? '可' : '否' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :hideCopy="false"
              @edit="$router.push({
                name: 'CreateClassTicket',
                params: {
                  ticketId: scope.row.id
                }
              })"
              @copy="$router.push({
                name: 'CreateClassTicket',
                params: {
                  ticketId: scope.row.id,
                },
                query: { copy: true },
              })"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="classTicketCount"
        @pageChange="refresh"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      btnString="刪除"
      @close="deleteDialog = false"
      @delete="deleteClassTicket(), deleteDialog = false"
    />
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import dayjs from '@/lib/dayjs'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { defaultRangeMax } from '@/validation'
import ThumbnailDefault from '@/assets/classTicket/thumbnail_default.svg'
import { checkUserFeature } from '@/store/modules/permission'
import { get } from 'lodash'

import {
  GetClassTicket,
  GetClassTicketCount,
  DeleteClassTicket,
} from '@/api/classTicket'
// Utils
import { pageStartIndex } from '@/utils/table'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'ClassTicketSetting',
  components: {
    DeleteDialog,
    EmptyBlock,
    Tag,
    TableEditBtnGroup,
  },
  data: () => ({
    ThumbnailDefault,
    defaultRangeMax,
    loading: false,
    nameSearch: '',
    // Dialog
    deleteDialog: false,
    selectRow: null,

    classTicketCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    classTicketList: [],
    imgSize: 90,
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userFeatures',
      'userPlanFeature',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    //  開放核銷
    useTicketUsagePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicketConfig.clientUsage')
    },
    //  開放轉贈
    useTicketGivePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicketConfig.clientGive')
    },
  },
  // async mounted () {
  //   await this.refresh()
  // },
  async activated () {
    if (get(this.$route.query, 'name')) {
      this.nameSearch = get(this.$route.query, 'name')
    }
    await this.refresh()
  },
  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      return type
    },

    async refresh () {
      this.loading = true
      await this.getClassTicket()
      await this.getClassTicketCount()
      this.loading = false
    },

    //= > 取得堂票
    async getClassTicket () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit

      const [res, err] = await GetClassTicket({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: (this.nameSearch === '') ? undefined : this.nameSearch,
      })
      if (err) {
        console.log(err)
        this.$message.error(err)
        return
      }
      this.classTicketList = res
    },

    //= > 取得堂票總數
    async getClassTicketCount () {
      const [res, err] = await GetClassTicketCount({
        shopId: this.shop,
        name: (this.nameSearch === '') ? undefined : this.nameSearch,
      })
      if (err) {
        console.log(err)
        this.$message.error(err)
        return
      }
      this.classTicketCount = res.count
    },

    //= > 刪除銷售類別
    async deleteClassTicket () {
      try {
        await DeleteClassTicket({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image || null)
    },
    dateFormat (date) {
      if (!date) return '-'
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
  },
}
</script>

<style scoped lang="scss">
.break-word {
  word-break: break-word
}
</style>
